/* eslint-disable max-statements */
import React from 'react';
import { Ripple } from 'primereact/ripple';
import { navigate } from 'gatsby-link';
import { connect } from 'react-redux';
import { checkPageLoading, logout } from '../state/auth/auth.actions';

const isBrowser = typeof window !== 'undefined';

const NavItem = ({ linkText, icon, logout, toast, clickEvent, link, checkPageLoading }) => {
  const handleClick = (ev) => {
    try {
      ev.preventDefault();
      if (clickEvent) clickEvent();
      if (link) {
        console.log(link);
        checkPageLoading(true);
        if (link && link === 'logout') logout(toast);
        if (link && link !== '' && link !== 'logout') {
          checkPageLoading(true);
          navigate(`${link}`);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <li onClick={handleClick}>
      <a className={`p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full sf-size-1
      ${isBrowser && window.location.pathname.includes(link) && link !== '/' ? 'text-yellow-800 hover:text-yellow-300' : isBrowser && window.location.pathname === '/' && link === '/' ? 'text-yellow-800 hover:text-yellow-300' : 'text-gray-400 hover:text-gray-900 hover:bg-yellow-500'}`}>
        <i className="pi mr-2">{icon}</i>
        <span>{linkText}</span>
        <Ripple />
      </a>
    </li>
  );
};

const mapStateToProps = (state) => ({
  toast: state.auth.toast
});

const mapDispatchToProps = (dispatch) => ({
  logout: (toast) => dispatch(logout(toast)),
  checkPageLoading: (state) => dispatch(checkPageLoading(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(NavItem);
