import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import firebase from 'gatsby-plugin-firebase';

import './layout.css';
import './fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import 'aos/dist/aos.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { connect } from 'react-redux';
import ClockLoader from 'react-spinners/ClockLoader';
import { checkPageLoading, checkUser, setToast } from '../state/auth/auth.actions';
import NavBar from './NavBar';
import Footer from './Footer';

const isBrowser = typeof window !== 'undefined';

const Layout = ({ children, pageLoading, checkPageLoading, checkUser, setToast, toast }) => {
  const ref = useRef();
  useEffect(() => {
    toast && toast.current ? null : setToast(ref);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        checkPageLoading(true);
        checkUser({ id: user.uid, email: user.email });
        setTimeout(() => user.uid ? checkPageLoading(false) : null, 1000);
      } else {
        checkPageLoading(true);
        checkUser({});
        setTimeout(() => checkPageLoading(false), 1000);
      }
    });
  }, []);
  return (
    <main>
      {pageLoading ? <div className="w-100 h-100vh d-flex center column bg-gray-900">
        <ClockLoader color={'#fbc02d'} background={'#263238'} customLoading={pageLoading} size={120} />
      </div>
        : <>
          {isBrowser && window.location.pathname.includes('admin') ? null : <NavBar />}
          {children}
          {isBrowser && window.location.pathname.includes('admin') ? null : <Footer />}
        </>}
    </main>
  );
};

const mapStateToProps = (state) => ({
  pageLoading: state.auth.pageLoading,
  toast: state.auth.toast,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  checkUser: (user) => dispatch(checkUser(user)),
  checkPageLoading: (state) => dispatch(checkPageLoading(state)),
  setToast: (state) => dispatch(setToast(state))
});

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
