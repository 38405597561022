import React, { useRef } from 'react';
import { StyleClass } from 'primereact/styleclass';
import { Ripple } from 'primereact/ripple';
import { FiHome, FiInfo } from 'react-icons/fi';
import { MdOutlineHomeRepairService } from 'react-icons/md';
import { GoTasklist } from 'react-icons/go';
import { IoMdLogOut } from 'react-icons/io';
import { connect } from 'react-redux';
import NavLink from './NavLink';

const NavBar = ({ user }) => {
  const btnRef4 = useRef();
  const handleClickEvent = (element) => {
    try {
      if (document.querySelector(element)) document.querySelector(element).scrollIntoView();
      if (!document.querySelector(element)) console.log('Does not exists');
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="bg-gray-900 py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static border-bottom-1 border-gray-800 animate__animated animate__slideInDown" style={{ minHeight: '84px', zIndex: '99999' }}>
      <h1 className="logo-font mr-0 lg:mr-6 text-yellow-800">NxPress</h1>
      <StyleClass nodeRef={btnRef4} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
        <a ref={btnRef4} className="p-ripple cursor-pointer block lg:hidden text-gray-400">
          <i className="pi pi-bars text-4xl"></i>
          <Ripple />
        </a>
      </StyleClass>
      <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-gray-900 left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
        <ul className="list-none p-0 ml-auto flex lg:align-items-center select-none flex-column lg:flex-row">
          <NavLink linkText="Home" link="/" icon={<FiHome />} />
          <NavLink linkText="About" icon={<FiInfo />} clickEvent={() => handleClickEvent('#about')} />
          <NavLink linkText="Services" icon={<MdOutlineHomeRepairService />}
            clickEvent={() => handleClickEvent('#services')} />
          <NavLink linkText="Spectialties" icon={<GoTasklist />}
            clickEvent={() => handleClickEvent('#specialties')} />
          {user && user.id ? <NavLink linkText="Logout" icon={<IoMdLogOut />}
            link="logout" /> : null}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(NavBar);
