import firebase from 'gatsby-plugin-firebase';

export const checkUser = (user) => ({
  type: 'check_user', user
});

export const addProfile = (profile) => ({
  type: 'profile', profile
});

export const setToast = (toast) => ({
  type: 'toast', toast
});

export const checkPageLoading = (pageLoading) => ({
  type: 'page_loading', pageLoading
});

export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch(checkUser({}));
      await firebase.auth().signOut();
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const logIn = (inputs, toast) => {
  return async () => {
    try {
      const { email, password } = inputs;
      await firebase.auth().signInWithEmailAndPassword(email, password);
      toast.current.show({
        severity: 'success',
        summary: 'Login Success',
        detail: 'Welcome to the admin panel',
        life: 5000
      });
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed Login',
        detail: error.message,
        life: 5000
      });
      console.log(error.message);
    }
  };
};
